import React, {createRef, useEffect, useState} from 'react';


type AnimationProps = {
    onAnimationEnded: () => void
}
const Animation = ({onAnimationEnded}: AnimationProps) => {

    const [videoPath, setVideoPath] = useState<string>('/video/animation-1920px.mp4')
    const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false)

    const ref = createRef<HTMLDivElement>()

    useEffect(() => {
        if (
            isVideoEnded === true
        ) {
            onAnimationEnded()
        }

    }, [isVideoEnded])

    useEffect(() => {
        console.log(videoPath)
    }, [])

    return (
        <div ref={ref} className={'animation'}>
            <div className={'video'}>
                <video onEnded={() => setIsVideoEnded(true)} className={'video__media'} src={videoPath}
                       autoPlay={true}
                       muted={true}>
                </video>
            </div>
        </div>

    );
};

export default Animation;